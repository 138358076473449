/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'jqxipEWigqzez23JePokTF.png': { uri: '/images/jqxipEWigqzez23JePokTF.png' },
'bhyxY1ciZj46txKfUQS4Cm.png': { uri: '/images/bhyxY1ciZj46txKfUQS4Cm.png' },
'qdQVh1SrgZ3cUKutb7FWCt.png': { uri: '/images/qdQVh1SrgZ3cUKutb7FWCt.png' },
'kx8x1j9B4j4DgeUGfWWQJJ.png': { uri: '/images/kx8x1j9B4j4DgeUGfWWQJJ.png' },
'kaVsscwGJC1L3T346Q8q5r.png': { uri: '/images/kaVsscwGJC1L3T346Q8q5r.png' },
'meyN1xGrUSE29JjRDskmww.png': { uri: '/images/meyN1xGrUSE29JjRDskmww.png' },
'xAYtU8dE6QM8FbEL41C3hw.png': { uri: '/images/xAYtU8dE6QM8FbEL41C3hw.png' },
'r6nCJe7VcjJX5biUHdVio6.png': { uri: '/images/r6nCJe7VcjJX5biUHdVio6.png' },
'85Sts8pVXosHLELa8AiRYW.png': { uri: '/images/85Sts8pVXosHLELa8AiRYW.png' },
'wVj5BUECRzzz4jWL31vt9j.png': { uri: '/images/wVj5BUECRzzz4jWL31vt9j.png' },
'mgyFSpMyA5PMwhSNxc8KGV.png': { uri: '/images/mgyFSpMyA5PMwhSNxc8KGV.png' },
'iQNHyN2eBFpDjF5xgY4m9H.png': { uri: '/images/iQNHyN2eBFpDjF5xgY4m9H.png' },
'3coghnay4vtmQ5vBfnUHjD.png': { uri: '/images/3coghnay4vtmQ5vBfnUHjD.png' },
'e9pKv28Uee4ZYYT6Y8DpVh.png': { uri: '/images/e9pKv28Uee4ZYYT6Y8DpVh.png' },
'eSmiQp6BqKg7TvDkEjyWVu.png': { uri: '/images/eSmiQp6BqKg7TvDkEjyWVu.png' },
'p9tHjAZB59WEq4uLAYyvHY.png': { uri: '/images/p9tHjAZB59WEq4uLAYyvHY.png' },
'axJTLxnPbnCHMv26yinJhZ.png': { uri: '/images/axJTLxnPbnCHMv26yinJhZ.png' },
'7Zjdepzn13wSqMCAL5Zkpp.png': { uri: '/images/7Zjdepzn13wSqMCAL5Zkpp.png' },
'krp5RxMKn8U7unFp6ZhiPq.png': { uri: '/images/krp5RxMKn8U7unFp6ZhiPq.png' },
'nnUgzU3K7jUgD3DVZV64E6.png': { uri: '/images/nnUgzU3K7jUgD3DVZV64E6.png' },
'emksxRAjfJbKUMjrpA9YAX.png': { uri: '/images/emksxRAjfJbKUMjrpA9YAX.png' },
'3iVPGoAsmWA6wDZjHN8M93.jpg': { uri: '/images/3iVPGoAsmWA6wDZjHN8M93.jpg' }
}

export default imageStaticSourcesByFileName
